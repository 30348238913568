import { localeMessages, NUMBER_FORMATS, DATETIME_FORMATS } from './locales'

export default defineI18nConfig(() => ({
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: true, // TOCHECK
  // locale: process.env.DEFAULT_LOCALE || 'de',
  messages: localeMessages,
  numberFormats: {
    en: NUMBER_FORMATS,
    fr: NUMBER_FORMATS,
    de: NUMBER_FORMATS,
    pt: NUMBER_FORMATS,
    es: NUMBER_FORMATS,
    it: NUMBER_FORMATS,
    sv: NUMBER_FORMATS,
    nl: NUMBER_FORMATS,
  },
  datetimeFormats: {
    en: DATETIME_FORMATS,
    fr: DATETIME_FORMATS,
    de: DATETIME_FORMATS,
    pt: DATETIME_FORMATS,
    es: DATETIME_FORMATS,
    it: DATETIME_FORMATS,
    sv: DATETIME_FORMATS,
    nl: DATETIME_FORMATS,
  },
}))